<template>
    <div class="baseMain">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="菜单目录" name="first">
                <div class="systemAddRouterMenu">
                    <!--:default-expanded-keys="[0]"   default-expand-all-->
                    <el-tree :data="treeData" node-key="id" default-expand-all :expand-on-click-node="false"
                             :props="defaultProps">
                        <template #default="{ node, data }">
                            <div class="custom-tree-node">
                                <div class="custom-tree-node-son">
                                    <el-icon :size="18" v-if="data.id == 0">
                                        <home-filled/>
                                    </el-icon>
                                    <el-icon :size="18" color="#409EFF"
                                             v-else-if="data.parent_id == 0 && data.type == 1">
                                        <folder-opened/>
                                    </el-icon>
                                    <span v-else-if="data.parent_id != 0 && data.type == 1" class="iconfont"
                                          :class="data.icon"></span>
                                    <!--<el-icon :size="18" color="#67C23A"-->
                                    <!--v-else-if="data.parent_id != 0 && data.type == 1">-->
                                    <!--<Menu/>-->
                                    <!--</el-icon>-->
                                    <el-icon :size="18" color="#E6A23C" v-else>
                                        <SetUp/>
                                    </el-icon>
                                    <span style="margin-left: 4px;">{{ data.name }}</span>
                                </div>
                                <div>
                                    <el-link v-if="data.id != 0" class="tableButton" type="primary"
                                             @click="append('编辑菜单目录',data)">编辑
                                    </el-link>
                                    <el-link v-if="data.id != 0" class="tableButton" type="danger"
                                             @click="remove(node, data)">删除
                                    </el-link>
                                    <el-link v-if="data.type == 1" class="tableButton" type="primary"
                                             @click="append('新建菜单目录',data)">添加
                                    </el-link>
                                </div>
                            </div>
                        </template>
                    </el-tree>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600px">
            <el-form ref="form" :model="form" label-width="80px" v-if="dialogVisible">
                <el-form-item label="分类">
                    <el-radio-group v-model="form.type">
                        <el-radio :label="1">菜单</el-radio>
                        <el-radio :label="2">功能</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="请求方式" v-if="form.type == '2'">
                    <el-select v-model="form.method" placeholder="请选择">
                        <el-option label="get" value="get"></el-option>
                        <el-option label="post" value="post"></el-option>
                        <el-option label="put" value="put"></el-option>
                        <el-option label="delete" value="delete"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地址">
                    <el-input v-model="form.url"></el-input>
                </el-form-item>
                <el-form-item label="图标地址">
                    <el-input v-model="form.icon"></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="form.sort"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="save">确 定</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>

    import {HomeFilled, FolderOpened, SetUp} from '@element-plus/icons'

    import {businessGet, businessPost, businessPut, businessDel} from '../../api/business.js'

    import {ElLoading} from 'element-plus'

    export default {
        name: "systemAddRouter",
        components: {
            HomeFilled,
            FolderOpened,
            SetUp
        },
        data() {
            return {
                activeName: 'first',
                treeData: null,
                defaultProps: {
                    children: 'son',
                    label: 'name',
                },
                dialogVisible: false,
                selMsg: null,
                dialogTitle: '',
                form: {
                    type: 1,
                    name: '',
                    method: '',
                    url: '',
                    icon: '',
                    sort: '',
                }
            }
        },
        created() {
            this.$nextTick(() => {
                this.getTreeData();
            })
        },
        methods: {
            save() {
                let url = '/admin/v1/auth';
                let data = {
                    type: this.form.type,
                    name: this.form.name,
                    url: this.form.url,
                    icon: this.form.icon,
                    sort: this.form.sort
                }
                if (this.form.type == 2) {
                    data.method = this.form.method;
                }
                let loadingInstance = ElLoading.service()
                if (this.dialogTitle == '新建菜单目录') {
                    data.parent_id = this.selMsg.id;
                    businessPost(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.dialogVisible = false;
                            this.getTreeData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                            loadingInstance.close();
                        }
                    })
                }
                if (this.dialogTitle == '编辑菜单目录') {
                    url = url + '/' + this.selMsg.id;
                    businessPut(url, data).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.dialogVisible = false;
                            this.getTreeData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                            loadingInstance.close();
                        }
                    })
                }
            },
            append(sort, data) {
                this.dialogTitle = sort;
                this.selMsg = JSON.parse(JSON.stringify(data));
                this.form.type = 1;
                this.form.name = '';
                this.form.url = '';
                this.form.method = '';
                this.form.icon = '';
                this.form.sort = '';
                if (this.dialogTitle == '编辑菜单目录') {
                    this.form.type = this.selMsg.type;
                    this.form.name = this.selMsg.name;
                    this.form.url = this.selMsg.url;
                    this.form.method = this.selMsg.method;
                    this.form.icon = this.selMsg.icon;
                    this.form.sort = this.selMsg.sort;
                }
                this.dialogVisible = true;
            },
            remove(node, data) {
                this.$confirm('此操作将删除该目录及该目录下的内容, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }).then(() => {
                    let url = '/admin/v1/auth' + '/' + data.id;
                    let loadingInstance = ElLoading.service()
                    businessDel(url, {}).then(res => {
                        loadingInstance.close();
                        if (res.data.status == 200) {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'success'
                            });
                            this.getTreeData();
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'warning'
                            });
                            loadingInstance.close();
                        }
                    })
                }).catch(() => {

                })
            },
            handleClick(tab, event) {
                console.log(tab, event)
            },
            getTreeData() {
                let url = '/admin/v1/auth';
                let loadingInstance = ElLoading.service()
                businessGet(url).then(res => {
                    loadingInstance.close();
                    if (res.data.status == 200) {
                        let msg = [
                            {
                                id: 0,
                                name: '招生通saas系统',
                                type: 1,
                                son: res.data.data,
                            }
                        ]
                        this.treeData = msg;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        },
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

    .systemAddRouterMenu {
        width: 600px;
        box-sizing: border-box;
    }

</style>
